<template>
  <div>
    <!-- <Loader /> -->
    <NavHeader
      :items="headerItem"
      :userprofile="profile"
      :homeURL="{ name: 'dashboard.home-1' }"
      :logo="logo"
      v-if="$route.name !== 'landing-page.watchvideo'"
    >
      <template slot="responsiveRight">
        <ul
          class="d-flex align-items-center justify-content-end list-inline m-0"
        ></ul>
      </template>
    </NavHeader>
    <Home
      id="home"
      v-if="this.$route.meta.slider === 'true' || this.$route.meta.movieslider"
    />

    <!-- add ramadan_bg.png in assets/images folder full width  -->

    <!-- banner -->
    <div
      v-if="$route.path === '/'"
      style="cursor: pointer"
      class="ramadan-container"
    >
      <div class="ramadan-container-content" v-if="$i18n.locale == 'en'">
        <h3>
          Get special <br />
          Ramadan content
        </h3>
        <button class="ramadan-container-btn" @click="ramadan_link()">
          Click here
        </button>
      </div>
      <div class="ramadan-container-content" v-else style="direction: rtl">
        <h3>
          احصل على محتوى <br />
          رمضان الخاص
        </h3>
        <button class="ramadan-container-btn" @click="ramadan_link()">
          {{ $i18n.locale == "en" ? "Click here" : "اضغط هنا" }}
        </button>
      </div>
      <img
        src="../assets/lantern1.png"
        class="lantern-img"
        alt="lantern"
        :style="{ right: $i18n.locale == 'en' ? '0' : 'auto' }"
      />
    </div>
    <!-- end banner -->

    <Slider v-if="this.$route.meta.category" />
    <!-- <BannerVideo
      v-if="
        this.$route.name == 'landing-page.detail' ||
          this.$route.name == 'landing-page.category-detail'
      "
    /> -->
    <Banner v-if="this.$route.name == 'landing-page.show-single'" />
    <Breadcrumb
      v-if="
        this.$route.name == 'landing-page.about' ||
        this.$route.name == 'landing-page.contact' ||
        this.$route.name == 'landing-page.privacypolicy' ||
        this.$route.name == 'landing-page.pricingplan1' ||
        this.$route.name == 'landing-page.pricingplan2' ||
        this.$route.name == 'landing-page.faq' ||
        this.$route.name == 'landing-page.blog' ||
        this.$route.name == 'landing-page.blogdetail' ||
        this.$route.name == 'landing-page.pricing-planning'
      "
    />
    <div :class="this.$route.meta.mainClass == '' ? '' : 'main-content'">
      <!-- <transition
        name="router-anim"
        :enter-active-class="`animated ${animated.enter}`"
        mode="out-in"
        :leave-active-class="`animated ${animated.exit}`"
      > -->
      <router-view :key="$route.path" />
      <!-- </transition> -->
    </div>
    <!-- <Footer v-if="$route.name!=='landing-page.watchvideo'"/> -->
    <div id="back-to-top">
      <a class="top" href="#top" id="top"> <i class="fa fa-angle-up"></i> </a>
    </div>
  </div>
</template>

<script>
import { core } from "../config/pluginInit"
// import Loader from '../components/core/loader/Loader'
import profile from "../assets/images/frontend/user/user.jpg"
import loader from "../assets/images/Ladies_White.png"
// import Footer from '../views/FrontendPages/Components/Footer/Footer'
import Home from "../views/FrontendPages/Components/Home/Home"
import NavHeader from "../components/core/navbars/FrontendNav"
// import BannerVideo from '../views/FrontendPages/MovieDetailPage/BannerVideo'
import Breadcrumb from "../views/FrontendPages/Components/Breadcrumb/Breadcrumb"

export default {
  name: "BackendLayout",
  components: {
    // Footer,
    // Loader,
    Home,
    NavHeader,
    // BannerVideo,
    Breadcrumb,
  },
  mounted() {
    core.index()
  },
  data() {
    return {
      profile: "",
      animated: { enter: "fadeInUp", exit: "fadeOut" },
      userProfile: profile,
      onlyLogo: false,
      onlyLogoText: false,
      logo: loader,
      headerItem: [
        { title: "Home", link: "/", child: false },
        { title: "Movies", link: "/movie-category", child: false },
        { title: "Tv Shows", link: "/show-category", child: false },
        {
          title: "pages",
          link: "#",
          child: true,
          children: [
            {
              title: "About Us",
              link: "/about",
              grandchild: false,
            },
            {
              title: "Contact",
              link: "/contact",
              grandchild: false,
            },
            {
              title: "Privacy-Policy",
              link: "/privacy-policy",
              grandchild: false,
            },
          ],
        },
      ],
    }
  },
  methods: {
    changeLogo(e) {
      this.logo = e
    },
    routerAnimationChange(e) {
      this.animated = e
    },
    ramadan_link() {
      this.$router.push({ name: "ramadan-landing-page" })
    },
  },
}
</script>

<style lang="scss">
@import url("../assets/css/custom.css");
@import url("../assets/css/developer.css");
@import "../assets/scss/frontend.scss";
</style>

<style>
@media (max-width: 425px) {
  .big-title {
    font-size: 2.5em !important;
  }
}

.ramadan-container {
  /* background: linear-gradient(135deg, #ffb347, #ffd700); */
  /* background-color: #2c3c3b; */
  /* background: linear-gradient(
    90deg,
    rgba(45, 44, 44, 1) 1%,
    rgba(48, 79, 58, 1) 81%
  ); */

  background: linear-gradient(
    90deg,
    rgba(28, 46, 47, 1) 1%,
    rgba(63, 78, 76, 1) 81%
  );

  /* border-radius: 16px; */
  position: relative;
  height: 200px;
}

.ramadan-container h3 {
  font-size: 18px !important;
}

.ramadan-container-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 60px 20px;
  text-align: start;
  color: white;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  z-index: 99;
}

.ramadan-container-btn {
  background-color: #bca467 !important;
  box-shadow: 0px 0px 16px 0px #bca467;
  color: #fff;
  border-radius: 6px;
  text-align: center;
  width: auto;
  padding: 0 20px;
  height: 40px;
  border: none;
  font-size: 14px;
}

.lantern-img {
  position: absolute;
  top: 0;
  width: 200px;
  height: 100%;
  object-fit: contain;
  z-index: 99;
}
</style>
