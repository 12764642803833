<template>
  <div class="main mt-5">
    <div class="d-flex flex-wrap justify-content-center">
      <div class="col-6 col-lg-3 mb-3" v-for="item in content" :key="item.id">
        <vue-load-image>
          <img
            slot="image"
            :src="item.thumbnail"
            class="img-fluid rounded mx-auto d-block square-image"
            alt="images"
            @click="gotoPage('/ramadan/detail/' + item.id)"
          />

          <img
            class="gif_load"
            slot="preloader"
            src="../../../assets/gif/loading.gif"
          />
          <img
            class="gif_load"
            slot="error"
            src="../../../assets/gif/error.gif"
          />
        </vue-load-image>
      </div>
    </div>
  </div>
</template>

<script>
import { core } from "../../../config/pluginInit"
import ApiService from "../../../services/api"
import VueLoadImage from "vue-load-image"

export default {
  components: {
    "vue-load-image": VueLoadImage,
  },
  data() {
    return {
      content: [],
      selectedImage: null,
      lang: localStorage.getItem("lang"),
      catId: this.$route.params.catId,
    }
  },
  mounted() {
    core.index()
    this.fetchContent()
  },
  methods: {
    // fetchCategoryDetail() {
    //   ApiService.myRamadanContents().then((response) => {
    //     this.content = response.data.data
    //   })
    // },
    fetchContent() {
      ApiService.fetchCategoryContent(this.catId).then((response) => {
        this.content = response.data
      })
    },

    goBack() {
      this.$router.push("/ramadan-landing-page")
    },

    gotoPage(path) {
      this.$router.push(path)
    },
  },
}
</script>

<style scoped>
.back-button {
  /* top: 20px;
      left: 20px;
      z-index: 1000; */
  background: #e4c275;
  color: #fff;
  border: none;
  padding: 9px 15px;
  zoom: 80%;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup img {
  max-width: 80%;
  max-height: 80%;
}

.main {
  background: #141414;
}

.square-image {
  cursor: pointer !important;
}
</style>
