<template>
  <div class="main">
    <button class="back-button mt-4 mb-4 mx-2" @click="goBack">
      <i class="fas fa-arrow-left"></i>
    </button>

    <div v-if="loading" class="text-center">
      <SkeletonLoader />
    </div>

    <div
      v-else-if="category.length != 0"
      class="d-flex flex-wrap justify-content-center"
    >
      <div class="col-6 col-lg-4 mb-3" v-for="item in category" :key="item.id">
        <!-- Card container -->
        <div
          class="card rounded"
          @click="gotoPage('/ramadan/detail/' + item.id)"
        >
          <!-- Set your desired background color here -->
          <!-- Image at the top of the card -->

          <vue-load-image>
            <img
              slot="image"
              :src="item.thumbnail"
              class="card-img-top square-image img-fluid rounded"
              alt=""
              @click="gotoPage('/ramadan/detail/' + item.id)"
            />

            <img
              class="gif_load"
              slot="preloader"
              src="../../../assets/gif/loading.gif"
            />
            <img
              class="gif_load"
              slot="error"
              src="../../../assets/gif/error.gif"
            />
          </vue-load-image>

          <!-- Card body with button -->
          <div class="card-body text-center">
            <button
              class="btn-orange"
              @click="gotoPage('/ramadan/detail/' + item.id)"
            >
              عرض
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="text-center p-4">
      <p v-if="this.$i18n.locale === 'en'">
        the content is not available to watch the content go to "Ramadan"
        Section
      </p>
      <p v-else-if="this.$i18n.locale === 'ar'">
        المحتوى غير متوفر لمشاهدة المحتوى اذهب إلى قسم رمضان
      </p>

      <p v-else>ناوەڕۆک بۆ سەیرکردن نییە بۆ سەیرکردن بڕۆ بۆ بەشی رەمەزان</p>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/api"
import AlertServie from "../../../services/errors"
import SkeletonLoader from "../Components/ListItems/ItemSkeleton"
import VueLoadImage from "vue-load-image"

export default {
  data() {
    return {
      category: [],
      selectedImage: null,
      loading: true,
    }
  },

  components: {
    SkeletonLoader,
    "vue-load-image": VueLoadImage,
  },

  mounted() {
    this.fetchCategoryDetail()
    this.myRamadanContents()
  },
  methods: {
    fetchCategoryDetail() {
      var categoryId = this.$route.params.category
      // Fetch category detail by categoryId
      ApiService.getRamadanContentByCategory(categoryId).then((response) => {
        this.category = response.data
        this.loading = false
      })
    },
    showImagePopup(imageUrl) {
      this.selectedImage = imageUrl
    },
    closeImagePopup() {
      this.selectedImage = null
    },
    goBack() {
      this.$router.push("/ramadan-landing-page")
    },

    myRamadanContents() {
      ApiService.myRamadanContents().then((response) => {
        this.$store.commit("setPoints", response.data.user_points)
      })
    },

    gotoPage(link) {
      var points = this.$store.state.points
      if (points > 0) {
        this.$router.push(link)
      } else {
        if (this.$i18n.locale === "en") {
          AlertServie.info(
            "You have used all your daily points. You will receive 5 more points tomorrow."
          )
        } else if (this.$i18n.locale === "ar") {
          AlertServie.info(
            ".لقد استخدمت كل نقاطك اليومية. ستحصل على 5 نقاط إضافية غدًا"
          )
        } else {
          AlertServie.info(
            ".هەموو خاڵەکانی ڕۆژانەت بەکارهێناوە. بەیانی 5 خاڵی تر وەردەگریت"
          )
        }
      }
    },
  },
}
</script>

<style scoped>
.back-button {
  /* top: 20px;
    left: 20px;
    z-index: 1000; */
  background: #b16d08;
  color: #fff;
  border: none;
  padding: 9px 15px;
  zoom: 80%;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
}

.card {
  background-color: #3a3b3c;
  border-radius: 10px;
}

/* center gif  */

.vue-load-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vue-load-image .gif_load {
  width: 50px;
  height: 50px;
}

.card .btn-orange {
  background-color: #b16d08;
  border: none;
  color: white;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup img {
  max-width: 80%;
  max-height: 80%;
}

.square-image {
  filter: blur(3px) !important;
  cursor: pointer !important;
}

.main {
  background: #141414 !important;
  margin-top: 40px !important;
}
</style>
